<template>
    <div>
        <h1>{{ $t('api_key.api_key_for', { system: `illi-${system.type}-${system.number}`}) }}</h1>
        <v-subheader>{{ $t('api_key.explanation') }}</v-subheader>
        <v-alert type="warning" v-if="alert.show" class="mt-4"
        >{{$t('api_key.warning')}}</v-alert>
        <v-btn @click="sendSetNewApiKey" v-if="!card.show" color="primary">{{ $t('api_key.create_new_key') }}</v-btn>
        <v-card elevation="2" class="mt-5" v-if="card.show" :loading="!card.gotData">
            <div v-if="card.gotData" class="pa-5">
                <h2>{{ $t('api_key.title_new_settings') }}</h2>
                <div class="ma-5">
                    <p class="font-weight-bold">{{ $t('api_key.your_new_key') }} {{this.card.api_key}}</p>
                </div>
                <p>{{ $t('api_key.steps.title') }}</p>
                <ol>
                    <li>{{ $t('api_key.steps.a') }}</li>
                    <li>{{ $t('api_key.steps.b') }}</li>
                    <v-card class="mt-4 pa-3" style="font-family: monospace" color="blue-grey lighten-5" id="command"> echo "{{ this.system.id }}" | tee ~/.api_credentials/id > /dev/null && echo "{{ this.card.api_key }}" | tee ~/.api_credentials/api_key > /dev/null</v-card>
                </ol>
            </div>
            <div v-if="!card.gotData" class="pa-5">
                <p>{{ $t('api_key.api_key_being_generated') }}</p>
            </div>
        </v-card>

        <v-divider></v-divider>

        <h1>{{ $t('MQTT.MQTT_for', { system: `illi-${system.type}-${system.number}`}) }}</h1>
        <v-subheader>{{ $t('MQTT.explanation') }}</v-subheader>
        <v-alert type="warning" v-if="alert.show" class="mt-4"
        >{{$t('MQTT.warning')}}</v-alert>
        <v-btn @click="sendSetNewApiKey" v-if="!card.show" color="primary">{{ $t('MQTT.create_new_key') }}</v-btn>
        <v-card elevation="2" class="mt-5" v-if="card.show" :loading="!card.gotData">
            <div v-if="card.gotData" class="pa-5">
                <h2>{{ $t('MQTT.title_new_settings') }}</h2>
                <div class="ma-5">
                    <p class="font-weight-bold">{{ $t('MQTT.your_new_key') }} {{this.card.api_key}}</p>
                </div>
                <p>{{ $t('MQTT.steps.title') }}</p>
                <ol>
                    <li>{{ $t('MQTT.steps.a') }}</li>
                    <li>{{ $t('MQTT.steps.b') }}</li>
                    <v-card class="mt-4 pa-3" style="font-family: monospace" color="blue-grey lighten-5" id="command"> echo "{{ this.system.id }}" | tee ~/.api_credentials/id > /dev/null && echo "{{ this.card.api_key }}" | tee ~/.api_credentials/api_key > /dev/null</v-card>
                </ol>
            </div>
            <div v-if="!card.gotData" class="pa-5">
                <p>{{ $t('MQTT.api_key_being_generated') }}</p>
            </div>
        </v-card>
    </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import {
    mapGetters,
    // mapActions
} from 'vuex'
// import axios from 'axios'
import { ILLI_AUTH_SERVER_URL } from '../config'
export default Vue.extend({
    name: "api_key",
    components: {
        //
    },
    data () {
        return {
            alert: {
                show: false,
            },
            card: {
                show: false,
                gotData: false,
                id: null,
                api_key: null,
            }
        }
    },
    computed: {
        ...mapGetters({
            system: "system/system",
            user: "auth/user",
        }),
    },
    methods: {
        sendSetNewApiKey () {
            this.card.show = true;
            this.alert.show = false;
            axios
            .put(`${ILLI_AUTH_SERVER_URL}/setAPIKey/`+this.system.id)
            .then(({data}) => {
                this.card.gotData = true;
                this.card.id = this.system.id
                this.card.api_key = data.api_key
            })
            .catch(({response}) => {
                console.log(response)
                this.stateTextGeneral = this.$t("systemMenu.syncStatesTexts.failed")
            })
        },
        sendxSetNewApiKey () {
            this.card.show = true;
            this.alert.show = false;
            axios
            .put(`${ILLI_AUTH_SERVER_URL}/setAPIKey/`+this.system.id)
            .then(({data}) => {
                this.card.gotData = true;
                this.card.id = this.system.id
                this.card.api_key = data.api_key
            })
            .catch(({response}) => {
                console.log(response)
                this.stateTextGeneral = this.$t("systemMenu.syncStatesTexts.failed")
            })
        }
    },
    mounted () {
        this.alert.show = (this.system.api_key);
    }
})
</script>